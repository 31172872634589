<div class="row d-flex justify-content-center">
	<div class="col-lg-10 col-xl-10 col-xxl-8 col-12 position-relative p-xs-0">
		<div class="modal-container d-flex flex-column create-patient-container">
			<div class="loader-container" [ngClass]="isLoading ? 'show' : '' ">
				<div [hidden]="false" class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
			<app-mobile-header *ngIf="isMobile && !(errorModalOpen || isLoading)" [currentMobileStep]="currentMobileStep" [class]="'step-' + currentMobileStep" />

			<anagraphic *ngIf="tabs[0].active" [tabId]="tabs[0].id" [isMobile]="isMobile" (nextMobileStep)="nextMobileStep()"></anagraphic>

			<anamnesi *ngIf="tabs[1].active" [tabId]="tabs[1].id" (handleGoBackToAnagraphic)="changeTab($event, true)" [isMobile]="isMobile" (nextMobileStep)="nextMobileStep()" (previousMobileStep)="previousMobileStep()"></anamnesi>

			<agreement *ngIf="tabs[2].active" [tabId]="tabs[2].id" [isMobile]="isMobile"></agreement>
		</div>
	</div>
</div>
