import { Component, OnInit, Input } from '@angular/core'
import { Patient, PatientObj } from 'src/app/models/patient.model'
import { Util } from 'src/app/models/util.model'
import { SessionService } from 'src/app/services/session.service'
import { TranslateService } from '@ngx-translate/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { OtpModal } from 'src/app/elements/otp-modal/otp.modal'
import { MessageModal, stateType } from 'src/app/elements/message-modal/message.modal'

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()
const PNF = require('google-libphonenumber').PhoneNumberFormat

import { faPen } from '@fortawesome/free-solid-svg-icons'
import { TokenResponse } from 'src/app/models/user.model'

@Component({
	selector: 'agreement',
	templateUrl: './agreement.component.html',
	styleUrls: ['./agreement.component.scss'],
})
export class AgreementComponent implements OnInit {
	@Input() tabId: number
	@Input() isMobile: boolean

	show: boolean
	patient: Patient
	phone: string
	dialCode: string

	editNumber: boolean
	otpServiceLoading: boolean
	otpPhoneError: boolean

	formattedOtpPhone: string

	currentModal

	faPen = faPen

	constructor(private session: SessionService, private modalService: NgbModal, public translator: TranslateService) {
		Util.debug('Constructor - AgreementComponent')

		this.patient = this.session.getPatient()
		this.show = true

		this.editNumber = false
		this.otpServiceLoading = false
		this.otpPhoneError = true

		this.formattedOtpPhone = ''

		let numbers = this.patient.phoneNumber.split(' ')

		this.phone = numbers[1]
		this.dialCode = numbers[0]

		this.validateOtpPhone()
	}

	ngOnInit(): void {}

	public getTerms() {
		// console.log(this.patient)
		window.open(this.patient.agreement.url, '_blank')
	}

	public validateOtpPhone() {
		// this.patient.phoneNumber = this.phone

		if (this.phone === '' || this.phone === undefined) {
			return (this.otpPhoneError = true)
		}

		const number = phoneUtil.parseAndKeepRawInput(this.phone, this.patient.countryObj.countryIso2)

		// console.log(number)

		this.otpPhoneError = !phoneUtil.isPossibleNumber(number)

		// if (!this.otpPhoneError) {
		// 	//aggiunge il dial code
		// 	this.formattedOtpPhone = phoneUtil.format(number, PNF.E164)

		// 	console.log(this.formattedOtpPhone)
		// }
	}

	public onSubmit() {
		// console.log(this.formattedOtpPhone)

		if (this.patient.agreementAccepted) {
			this.otpServiceLoading = true

			this.formattedOtpPhone = this.dialCode + this.phone
			this.patient.phoneNumber = this.dialCode + ' ' + this.phone //lo cambio nel caso sia stato e ditato e aggungo lo spazio, cosí quando mi arriva a FE distinguo il dialcode dal numero

			// console.log(this.formattedOtpPhone)

			this.session
				.otpInit({ action: 'client', lang: this.patient.country, phoneNumber: this.formattedOtpPhone, isEmail: 'N', email: '' })
				.then((res) => {
					// this.otpMessageID = res.messageID

					this.currentModal = this.modalService.open(OtpModal, { size: 'l', keyboard: false, backdrop: 'static' })
					this.currentModal.componentInstance.country = this.patient.country
					this.currentModal.componentInstance.formattedOtpPhone = this.formattedOtpPhone
					this.currentModal.componentInstance.otpMessageID = res.messageID
					this.currentModal.componentInstance.otpExpiringInSec = res.expiringInSec
					this.currentModal.componentInstance.action = 'client'
					this.currentModal.componentInstance.otpExpiringInSec = res.expiringInSec
					// Per ora usiamo solo OTP via SMS
					this.currentModal.componentInstance.otpViaEmail = false
					this.currentModal.componentInstance.otpEmail = ''
					this.currentModal.componentInstance.isOptician = false

					this.currentModal.result.then(
						(response: TokenResponse) => {
							// console.log(response)
							this.patient.tokenResponse = response
							let patObj: PatientObj = new PatientObj(this.patient)
							patObj.id = this.tabId

							this.show = false
							this.session.createPatient.next(patObj)
						},
						(reason) => {
							Util.debug('mopdal dismissed maybe to chnage phone number - do nothing')
						}
					)
				})
				.catch(() => {
					let text = [this.translator.instant('MODAL.INTERNAL_ERROR')]

					this.modalService.dismissAll()

					this.currentModal = this.modalService.open(MessageModal, { size: 'l', keyboard: false, backdrop: 'static' })
					this.currentModal.componentInstance.state = stateType.FAIL
					this.currentModal.componentInstance.texts = text
				})
				.finally(() => {
					this.otpServiceLoading = false
				})
		} else {
			console.log('agreement not accepted')
		}
	}
}
