import { Component, Input, AfterViewInit, OnInit, EventEmitter, Output } from '@angular/core'
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms'
import { Anamnesis, PatientReply } from 'src/app/models/anamnesis.model'
import { Patient, PatientObj } from 'src/app/models/patient.model'

import { SessionService } from 'src/app/services/session.service'

import { faXmark, faCheck, faArrowRight, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { Util } from 'src/app/models/util.model'
import { TranslateService } from '@ngx-translate/core'

interface GroupedQuestions {
	substep: String
	questions: Anamnesis[]
}

interface QuestionDependency {
	q: string[]
	r: {
		[key: string]: number[]
	}
}

@Component({
	selector: 'anamnesi',
	templateUrl: './anamnesi.component.html',
	styleUrls: ['./anamnesi.component.scss'],
})
export class AnamnesiComponent implements AfterViewInit, OnInit {
	@Input() tabId: number
	@Input() isMobile: boolean
	@Output() handleGoBackToAnagraphic = new EventEmitter<number>()
	@Output() nextMobileStep = new EventEmitter()
	@Output() previousMobileStep = new EventEmitter()

	patient: Patient
	show: boolean
	formValid: boolean

	groupedQuestions: GroupedQuestions[]
	steps: string[]
	currentStep: number = 1

	faCheck = faCheck
	faArrowRight = faArrowRight
	faQuestionCircle = faQuestionCircle

	// anamnesiform: FormGroup

	constructor(private session: SessionService, private translator: TranslateService) {
		Util.debug('Constructor - AnamnesiComponent')

		this.patient = this.session.getPatient()
		this.formValid = false
		this.show = true

		// console.log(this.patient)

		// this.anamnesiform = new FormGroup({
		// 	questions: new FormArray([]),
		// })

		// for (let i = 0; i < this.patient.anamnesi.length; i++) {
		// 	const question = this.patient.anamnesi[i]

		// 	const control = new FormControl(question, Validators.required)

		// 	;(<FormArray>this.anamnesiform.get('questions')).push(control)
		// }

		// console.log(this.anamnesiform)
	}

	ngOnInit(): void {
		if (this.patient.anamnesi.length === 0) {
			let patObj: PatientObj = new PatientObj(this.patient)
			patObj.id = this.tabId

			this.session.createPatient.next(patObj)
		} else {
			this.groupedQuestions = Object.values(
				this.patient.anamnesi.reduce((acc, question) => {
					const levelParts = question.level.split('.')
					const substep = levelParts[1] // Get the substep (second number of level)

					if (!acc[substep]) {
						acc[substep] = {
							substep,
							questions: [],
						}
					}

					if (levelParts[2] !== '0') {
						acc[substep].questions.push(question)
					}

					return acc
				}, {} as { [key: string]: GroupedQuestions })
			)

			// Convert the grouped object into an array of GroupedQuestions
			this.steps = this.groupedQuestions.map((el) => this.translator.instant(`CREATE_PATIENT.ANAMNESIS.SUBSTEP_TITLE_${el.substep}`))
		}
	}

	ngAfterViewInit(): void {
		window.scrollTo(0, 0)
	}

	// getControls() {
	// 	return (<FormArray>this.anamnesiform.get('questions')).controls
	// }

	toggle(obj: Anamnesis, n: number) {
		// console.log(obj)
		let replies = obj.replies

		if (obj.input_type === 'radio') {
			for (let i = 0; i < replies.length; i++) {
				replies[i].checked = false
			}
		}

		//imposto true solo quella selezionata
		replies[n].checked = !replies[n].checked
	}

	onSliderInputChange(event: Event, question: Anamnesis) {
		const val = (event.target as HTMLInputElement).value
		let replies = question.replies

		for (let i = 0; i < replies.length; i++) {
			replies[i].checked = false
		}

		//imposto true solo quella selezionata
		replies[val].checked = !replies[val].checked
	}

	getSliderDefaultValue(question: Anamnesis): number {
		const checkedAnswerIndex = question.replies.findIndex((answer) => answer.checked)
		return checkedAnswerIndex >= 0 ? checkedAnswerIndex : 0 // Fallback to 0 if none is checked
	}

	questionDependenciesSatisfied(question: Anamnesis): boolean {
		try {
			const dependency: QuestionDependency = JSON.parse(question.depends_on)

			let flag = true

			dependency.q.forEach((depQuestionLevel) => {
				const depQuestion = this.patient.anamnesi.find((anamQuestion) => anamQuestion.level === depQuestionLevel)

				if (depQuestion) {
					// Check if any of the specified replies are checked
					const isAnyChecked = dependency.r[depQuestionLevel].some((replyIndex) => {
						const replyId = depQuestion.replies[replyIndex - 1].reply_id
						const replyElement = document.getElementById(`btn-check-${depQuestion.question_id}-${replyId}`) as HTMLInputElement
						return replyElement && replyElement.checked
					})

					if (!isAnyChecked) {
						flag = false
					}
				}
			})

			// If dependencies are not satisfied, uncheck all inputs of the current question
			if (!flag) {
				question.replies.forEach((answer) => {
					const replyElement = document.getElementById(`btn-check-${question.question_id}-${answer.reply_id}`) as HTMLInputElement
					if (replyElement) {
						answer.checked = false
						replyElement.checked = false
					}
				})
			}

			return flag
		} catch (error) {
			console.log('There was an error checking the dependency question(s):', error)
		}
	}

	onSubmit() {
		const patientReply: PatientReply[] = []
		this.groupedQuestions.forEach((group) => {
			group.questions.forEach((question) => {
				const replyCheckedIds = question.replies
					.filter((reply) => reply.checked)
					.map((reply) => {
						return reply.reply_id
					})
				patientReply.push({
					question_id: question.question_id,
					reply_ids: replyCheckedIds,
				})
			})
		})

		this.patient.reply = patientReply

		let patObj: PatientObj = new PatientObj(this.patient)
		patObj.id = this.tabId

		this.show = false

		this.nextMobileStep.emit()
		this.session.createPatient.next(patObj)
	}

	goBack() {
		this.previousMobileStep.emit()
		this.currentStep > 1 ? this.currentStep-- : this.handleGoBackToAnagraphic.emit(0)
	}

	goNext() {
		this.nextMobileStep.emit()
		this.currentStep++
	}
}
