import { Component, Input } from '@angular/core'

@Component({
	selector: 'app-mobile-header',
	templateUrl: './mobile-header.component.html',
	styleUrl: './mobile-header.component.scss',
})
export class MobileHeaderComponent {
	@Input() currentMobileStep: number
}
